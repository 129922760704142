<template>
  <AuthChangePassword />
</template>
<script>
import AuthChangePassword from "@/components/partials/portal/AgentSettingChangePassword.vue";
export default {
  components: {
    AuthChangePassword,
  },
  setup() {},
};
</script>
