<template>
  <div class="loader"></div>
</template>
<style scoped>
/* HTML: <div class="loader"></div> */
.loader {
  width: 25px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.5) 30%,
        #c0c0c000 0 70%,
        rgb(224, 224, 224) 0
      )
      50%/8% 100%,
    linear-gradient(
        90deg,
        rgba(153, 151, 151, 0.25) 30%,
        #0000 0 70%,
        rgba(206, 205, 205, 0.75) 0
      )
      50%/100% 8%;
  background-repeat: no-repeat;
  animation: l23 1s infinite steps(12);
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}
.loader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}
@keyframes l23 {
  100% {
    transform: rotate(1turn);
  }
}
</style>
