<template>
  <HeroSetting />
  <div class="wrapper section">
    <router-view />
  </div>

  <app-menu-bar />
</template>
<script>
import HeroSetting from "@/components/common/HeroSetting.vue";
export default {
  components: {
    HeroSetting,
  },
  setup() {
    return {};
  },
};
</script>
<style scoped>
.wrapper {
  margin: 30px 20px;
}
.section {
  margin-bottom: 75px;
}
</style>
