import axios from "axios";
import SecretData from "@/utils/secret/secretKey";
const { generateSecretKey } = SecretData();

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
  function (config) {
    let secretDecryptKey = generateSecretKey();

    // Set the x-api-key header
    config.headers["x-api-key"] = secretDecryptKey;

    // Set the Authorization header
    config.headers.Authorization = localStorage.getItem(
      `${process.env.VUE_APP_AUTH_TOKEN}_XXX`
    );

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
