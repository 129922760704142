<template>
  <AuthIndex />
</template>
<script>
import AuthIndex from "@/components/partials/portal/AgentProfile.vue";
export default {
  components: {
    AuthIndex,
  },
  setup() {},
};
</script>
