<template>
  <div class="wrapper">
    <div class="container">
      <div class="divider">
        <div class="header-title">
          <h2>Terms and Condition</h2>
        </div>
        <div class="content">
          <p>
            Integrated Management Services, Inc. offers ANEC membership card
            loaded with benefits and privileges, with one-time payment feature
            allowing the buyer to gain immediate access to affordable yet
            state-of-the-art holistic services.
          </p>
          <p>
            1. Hospitalization coverage pertains to diseases/illnesses or
            circumstances that require medical assistance.<br />
            2. Our provider abides by the standard HMO regulations concerning
            Exclusions, Pre-existing Conditions and Contestability clause;<br />
            3. Perks and value added benefits may be viewed by scanning the QR
            code in front of the virtual membership card.<br />
            4. Virtual membership card will be effective from date of purchase,
            complete with corresponding payments;<br />
            5. Coverage takes effect upon effectivity of the virtual membership
            card and up to 12 months thereafter; <br />
            6. Purchase of the product is bound by age requirement • between
            ages 18 and 63 years old. • Exit age is 65;<br />
            7. Virtual membership card shall be sent thru electronic mail of the
            buyer as proof of membership.<br />
          </p>
          <h3>Payment Terms</h3>
          <div class="terms">
            1. The membership fee is to be paid annually through any of the
            following options:
            <ul>
              <li>Credit Card/ Debit Card (via POS)</li>
              <li>Credit Card (via Dragon Pay)</li>
              <li>G-Cash (via Dragon Pay)</li>
              <li>Check Payment</li>
            </ul>

            2. Paid Membership fee is refundable within 15 days “free look”
            period, during which the member may review the provisions of the
            program and if no availment has been recorded, the member may opt to
            cancel his/her membership and request for a refund.
          </div>
          <p>
            <b
              >To continue please click the
              <span class="link" @click="pecTermCondtion(), (isRead = true)"
                >Pre-Existing Conditions (PEC) and Exclusions</span
              ></b
            >
          </p>
          <div
            class="multiples"
            id="preview"
            v-for="(items, index) in item"
            :key="index"
          >
            <img v-if="items.imageUrl" :src="items.imageUrl" />
            <removeSVG @click="removeHandler(index)" />
          </div>
          <div class="confirmation">
            <input v-model="isConfirm" type="checkbox" />
            <span>
              I hereby declare that I have read, understand and agree to the
              terms and conditions stated herein.
            </span>
          </div>
        </div>
        <div class="pagination">
          <div class="btn-action">
            <input
              type="button"
              value="Back"
              @click="$emit('actionPage', 'ClientInformation')"
            />
            <input type="button" value="Next" @click="nextHandler()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import validationAlert from "@/utils/reusables/SwalAlert";
import removeSVG from "@/components/svgs/removeSVG.vue";
import { ref, reactive, inject, onMounted } from "vue";
export default {
  components: {
    removeSVG,
  },
  setup(props, { emit }) {
    const { termCondtionPortal, pecTermCondtion } = validationAlert();
    const store = inject("$store");
    const isConfirm = ref(false);
    const isRead = ref(false);
    const fileData = ref(null);
    const uploadFile = ref(null);
    const profileURL = ref(null);
    const fileItems = ref([]);
    const getProfile = ref(null);
    const item = reactive([]);
    onMounted(() => {
      if (store.state.ifRetrievedData) {
        isConfirm.value = true;
        isRead.value = true;
      } else {
        isConfirm.value = false;
        isRead.value = false;
      }
    });
    const nextHandler = () => {
      if (isConfirm.value && isRead.value) {
        store.state.uploadMultiple = item;
        emit("actionPage", "PaymentConfirmation");
      } else if (!isRead.value) {
        termCondtionPortal("link");
      } else if (!isConfirm.value) {
        termCondtionPortal("checkbox");
      } else {
        termCondtionPortal("both");
      }
    };
    return {
      isConfirm,
      isRead,
      nextHandler,
      termCondtionPortal,
      pecTermCondtion,
      fileData,
      uploadFile,
      profileURL,
      fileItems,
      getProfile,
      item,
    };
  },
};
</script>
<style scoped>
.wrapper {
  color: var(--semi-light-color);
}
.divider {
  display: flex;
  flex-direction: column;
}
.content {
  margin: 0px 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.content h3 {
  padding: 14px 0px;
  color: var(--secondary-color);
}
.content p,
.terms {
  color: var(--semi-light-color);
  font-size: 15px;
  line-height: 28px;
}
.content .confirmation {
  padding: 20px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
}
.content .confirmation input {
  width: 34px;
  height: 34px;
}
.content .confirmation span {
  font-weight: 700;
  font-style: italic;
}
.pagination {
  margin: 20px 30px;
}
.pagination .btn-action {
  display: flex;
  justify-content: space-between;
}
.link {
  color: var(--secondary-color);
  font-weight: 600;
}
li {
  display: list-item;
  list-style-position: outside;
  list-style-type: circle;
  margin-left: 40px;
  color: var(--semi-light-color);
  line-height: 28px;
}
</style>
