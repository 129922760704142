<template>
  <div class="group-container card-palette">
    <h1>-</h1>
    <h5>most production in a single day</h5>
  </div>
  <div class="group-container card-palette">
    <h1>-</h1>
    <h5>highest production in a month</h5>
  </div>
</template>
<script>
import systemVersion from "@/utils/reusables/SystemVersion";
export default {
  setup() {
    const { appVersion } = systemVersion();

    return {
      appVersion,
    };
  },
};
</script>
<style scoped>
.group-container {
  margin: 12px 0px;
  min-height: 100px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.group-container h1 {
  font-size: 48px;
  font-weight: 700;
  color: var(--accent-color);
}
.group-container h5 {
  font-weight: 400;
  color: var(--semi-light-color);
}
</style>
