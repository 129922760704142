<template>
  <QRGenerator />
</template>
<script>
import QRGenerator from "@/components/partials/general/QRGenerator.vue";
export default {
  components: {
    QRGenerator,
  },
  setup() {},
};
</script>
