<template>
  <AuthLogin />
</template>
<script>
import AuthLogin from "@/components/partials/portal/AuthLogin.vue";
export default {
  components: {
    AuthLogin,
  },
  setup() {},
};
</script>
