<template>
  <AgentClient />
</template>
<script>
import AgentClient from "@/components/partials/portal/AgentPortfolioClient.vue";
export default {
  components: {
    AgentClient,
  },
  setup() {},
};
</script>
