export default function secretFiles() {
  const CryptoJS = require("crypto-js");

  const generateSecretKey = () => {
    let secretKey = "JGEll7p6J2xSGOMEZA";
    let encryptKey = encryptData(secretKey, process.env.VUE_APP_API_KEY);
    localStorage.setItem(`${process.env.VUE_APP_API_KEY}`, encryptKey);
    let decryptedData = decryptData(encryptKey, process.env.VUE_APP_API_KEY);
    return decryptedData;
  };

  const encryptData = (data, secretKey) => {
    const ciphertext = CryptoJS.AES.encrypt(data, secretKey).toString();
    return ciphertext;
  };
  const decryptData = (encryptedData, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const originalData = bytes.toString(CryptoJS.enc.Utf8);
    return originalData;
  };
  return {
    encryptData,
    decryptData,
    generateSecretKey,
  };
}
