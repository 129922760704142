<template>
  <nav class="bottom-nav">
    <div v-for="(item, index) in itemMenu" :key="index">
      <router-link :to="item.path" class="router">
        <component class="icon" :is="item.icon"></component>
      </router-link>
    </div>
  </nav>
</template>
<script>
import { reactive } from "vue";
import HomeIcon from "@/components/svgs/homeSVG.vue";
import AccountIcon from "@/components/svgs/accountSVG.vue";
import SettingIcon from "@/components/svgs/settingSVG.vue";
export default {
  components: {
    HomeIcon,
    AccountIcon,
    SettingIcon,
  },
  setup() {
    const itemMenu = reactive([
      {
        name: "Portfolio",
        path: "/admin/auth/portfolio",
        icon: "HomeIcon",
      },
      {
        name: "Profile",
        path: "/admin/auth/profile",
        icon: "AccountIcon",
      },
      {
        name: "Setting",
        path: "/admin/auth/setting",
        icon: "SettingIcon",
      },
    ]);
    return {
      itemMenu,
    };
  },
};
</script>

<style scoped>
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  clear: both;
  background-color: var(--primary-color);
  background: linear-gradient(90deg, #7a395c 10%, rgb(174, 64, 94) 90%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.nav-item {
  color: #fff;
  text-align: center;
  text-decoration: none;
  flex: 1;
  padding: 10px 0;
}

.nav-item i {
  display: block;
  font-size: 1.5rem;
}

.nav-item span {
  display: block;
  font-size: 0.75rem;
  margin-top: 5px;
}
.icon {
  height: 32px;
  width: 32px;
}
.router {
  color: initial; /* or specify a different color */
}
</style>
