<template>
  <div class="information card-palette">
    <div class="item-container">
      <ul>
        <li class="title">Version:</li>
        <li class="value">{{ appVersion.replace("Version", "").trim() }}</li>
      </ul>
      <ul>
        <li class="title">Copyright:</li>
        <li class="value">2024 | IWC Dev</li>
      </ul>
      <ul>
        <li class="title">Report issue:</li>
        <li class="value">
          <a
            href="mailto:contact@alvinvergara.com?subject=Inquiry&body=Hello%20Alvin,%0D%0A%0D%0AI'd%20like%20to%20ask%20about..."
            >contact@alvinvergara.com</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import systemVersion from "@/utils/reusables/SystemVersion";
export default {
  setup() {
    const { appVersion } = systemVersion();

    return {
      appVersion,
    };
  },
};
</script>
<style scoped>
.information {
  margin: 12px 0px;
}

.item-container {
  padding: 14px;
}
.information ul {
  display: flex;
  justify-content: space-between;
  font-size: 0.85em;
  margin: 10px 0px;
}
.information ul .title {
  color: var(--semi-light-color);
  font-weight: 400;
}
.information ul .value {
  color: var(--light-color);
  font-weight: 500;
}
a {
  color: var(--light-color);
}
</style>
