<template>
  <footer>
    <span @click="routeHandler">
      <h5>Marketing Executive Portal</h5>
    </span>
    <h6 class="version">{{ appVersion }}</h6>
  </footer>
</template>
<script>
import systemVersion from "@/utils/reusables/SystemVersion";
import { inject } from "vue";
export default {
  setup() {
    const router = inject("$router");
    const { appVersion } = systemVersion();
    const routeHandler = () => {
      router.push({ name: "authlogin" });
    };

    return {
      routeHandler,
      appVersion,
    };
  },
};
</script>
<style scoped>
footer {
  margin: 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--light-color);
  flex-direction: column;
  gap: 10px;
}
h6 {
  font-weight: 400;
}
</style>
