<template>
  <div class="container">
    <div class="profile">
      <img src="../../../assets/images/agent.png" alt="" />
      <h3>Alvin Vergara</h3>
    </div>
    <div class="qr-container">
      <qrCode
        class="qr-code"
        :text="qrContent"
        size="130"
        error-level="Q"
      ></qrCode>
    </div>
    <div class="qr-container">
      <qrCode
        class="qr-code"
        :text="qrContent"
        size="130"
        error-level="Q"
      ></qrCode>
    </div>
    <div class="qr-container">
      <qrCode
        class="qr-code"
        :text="qrContent"
        size="130"
        error-level="Q"
      ></qrCode>
    </div>
    <div class="qr-container">
      <qrCode
        class="qr-code"
        :text="qrContent"
        size="130"
        error-level="Q"
      ></qrCode>
    </div>
    <div class="qr-container">
      <qrCode
        class="qr-code"
        :text="qrContent"
        size="130"
        error-level="Q"
      ></qrCode>
    </div>
  </div>
</template>

<script>
import qrCode from "vue-qrcode-component";
import { ref } from "vue";

export default {
  components: {
    qrCode,
  },
  setup() {
    const qrContent = ref("https://application.iwcdeped.com/");
    return {
      qrContent,
    };
  },
};
</script>
<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  flex-direction: column;
}
.profile {
  padding: 18px;
}
.profile img {
  height: 130px;
  width: 130px;
}
.profile h3 {
  padding: 8px 0px;
}
.qr-container {
  background-color: #fff;
  height: 160px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
</style>
