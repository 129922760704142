<template>
  <div class="wrapper">
    <div class="container">
      <h2 class="header-title">Plan Code</h2>
      <h5 class="label-note">
        Input plan code discussed and presented by the agent.
      </h5>
      <form class="content" @submit.prevent="searchHandler">
        <input
          class="search"
          v-model.trim="planCode"
          @input="inputHandler"
          type="text"
          name="plancode"
          placeholder="E.g DEPED-XXX"
          :class="{
            error: !isNext && isMessageShow,
          }"
          required
        />
        <div class="loading-container">
          <app-loading v-if="isLoading" />
          <input
            class="btn-submit"
            type="submit"
            :value="!isLoading ? 'Search Plan code' : ''"
          />
        </div>

        <span
          v-if="isMessageShow"
          class="message"
          :class="isNext ? 'valid' : 'invalid'"
        >
          <h4>{{ resMessage }}</h4>
          <verifiedSVG v-if="isNext" /> <sadSVG v-else />
        </span>
      </form>
      <div class="pagination">
        <div class="btn-action">
          <input
            type="button"
            value="Back"
            @click="$emit('actionPage', 'AgentProfile')"
          />
          <input type="button" value="Next" @click="nextHandler()" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import verifiedSVG from "@/components/svgs/verifiedSVG.vue";
import sadSVG from "@/components/svgs/sadSVG.vue";
import ApplicationData from "@/data/ApplicationData";
import { ref, inject, onMounted } from "vue";
export default {
  components: {
    verifiedSVG,
    sadSVG,
  },
  setup(props, { emit }) {
    const store = inject("$store");
    const planCode = ref(null);
    const { planInfo, fetchPlanItems } = ApplicationData();
    const isLoading = ref(false);
    const isMessageShow = ref(false);
    const isNext = ref(false);
    const resMessage = ref(null);
    const planDetails = ref([]);
    onMounted(() => {
      if (store.state.ifRetrievedData) {
        planCode.value = store.state.formData.plancode;
        isNext.value = true;
        isMessageShow.value = true;
        resMessage.value = "Plan code successfully verified";
        planDetails.value = store.state.planDetails;
        store.state.planDetails = planDetails.value;
      } else {
        planCode.value = null;
        isNext.value = false;
        isMessageShow.value = false;
        resMessage.value = null;
      }
    });
    const inputHandler = () => {
      if (planCode.value.length) {
        isMessageShow.value = false;
      }
    };
    const searchHandler = async () => {
      isLoading.value = true;
      isMessageShow.value = false;
      if (planCode.value) {
        planInfo.value = await fetchPlanItems(planCode.value);
      }

      setTimeout(() => {
        isLoading.value = false;
        if (planInfo.value && planInfo.value.length) {
          isNext.value = true;
          isMessageShow.value = true;
          resMessage.value = "Plan code successfully verified";
          planDetails.value = planInfo.value[0];
          store.state.planDetails = planInfo.value[0];
        } else {
          isMessageShow.value = true;
          isNext.value = false;
          resMessage.value =
            "Plan code not valid, Please re-enter the correct plan code";
          planCode.value = null;
        }
      }, 3000);
    };
    const nextHandler = () => {
      isNext.value
        ? emit("actionPage", "PlanDetails", planDetails.value)
        : null;
    };
    return {
      planCode,
      planInfo,
      searchHandler,
      isLoading,
      isMessageShow,
      isNext,
      resMessage,
      planDetails,
      inputHandler,
      nextHandler,
      fetchPlanItems,
    };
  },
};
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
}
.content {
  margin: 12px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.content input .search {
  min-width: 240px;
}
.content .btn-submit {
  margin: 5px 0px;
  width: 180px;
}
.content .message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 5px;
  font-size: 13px;
}
.valid {
  color: var(--valid-color);
}
.invalid {
  color: var(--error-color);
}
.pagination {
  margin: 20px 30px;
}
</style>
