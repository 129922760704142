<template>
  <div class="setting-container">
    <form class="form-group" @submit.prevent="submitHandler()">
      <input
        class="card-palette"
        type="password"
        v-model="oldPassword"
        placeholder="current password"
        autocomplete="current-password"
        required
      />
      <input
        class="card-palette"
        type="password"
        v-model="newPassword"
        placeholder="new password"
        autocomplete="new-password"
        required
      />
      <input
        class="card-palette"
        type="password"
        v-model="rePassword"
        placeholder="retype-password"
        autocomplete="new-password"
        required
      />
      <div class="loading-container">
        <app-loading v-if="isLoading" />
        <input
          class="submit"
          type="submit"
          :value="isLoading ? '' : 'Update Password'"
        />
      </div>
    </form>
    <div
      v-if="isMessage"
      class="messageHandler"
      :class="isSuccess ? 'success' : 'failed'"
    >
      {{ isMessageText }}
    </div>
  </div>
</template>
<script>
import ChangePassword from "@/data/AccountPortalData";
import { reactive, toRefs } from "vue";
export default {
  components: {},
  setup() {
    const {
      authChangePasswordHandler,
      isLoading,
      isMessage,
      isMessageText,
      isSuccess,
    } = ChangePassword();
    const formData = reactive({
      oldPassword: null,
      newPassword: null,
      rePassword: null,
    });
    const submitHandler = () => {
      isLoading.value = true;
      authChangePasswordHandler(formData);
    };
    return {
      authChangePasswordHandler,
      submitHandler,
      ...toRefs(formData),
      isLoading,
      isSuccess,
      isMessage,
      isMessageText,
    };
  },
};
</script>
<style scoped>
.form-group input {
  margin: 3px 0px;
  min-width: 100%;
}
.form-group input[type="submit"] {
  margin: 20px 0px;
}
.messageHandler {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px 0px;
  border-radius: 10px;
  color: var(--light-color);
  font-weight: 500;
}
.success {
  background-color: var(--valid-color);
}
.failed {
  background-color: var(--error-color);
}
input::placeholder {
  color: var(--semi-light-color);
}
.loading-container {
  margin: 10px 0px;
}
.submit {
  min-height: 50px;
}
</style>
