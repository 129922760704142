<template>
  <AgentPortfolioClientProfile />
</template>
<script>
import AgentPortfolioClientProfile from "@/components/partials/portal/AgentPortfolioClientProfile.vue";
export default {
  components: {
    AgentPortfolioClientProfile,
  },
  setup() {},
};
</script>
