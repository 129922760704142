<template>
  <div class="wrapper">
    <div class="logo">
      <img src="../../../assets/brand-logo.png" />
    </div>
    <div class="container">
      <h1>Marketing Executive Portal</h1>
      <form @submit.prevent="submitHandler()">
        <div class="form-group">
          <div class="input">
            <input
              type="email"
              v-model="username"
              placeholder="Email Address"
              required
            />
          </div>
        </div>
        <div class="form-group">
          <div class="input">
            <input
              type="password"
              v-model="password"
              placeholder="Password"
              required
            />
          </div>
        </div>
        <div class="action-button">
          <div class="loading-container">
            <app-loading v-if="isLoading" />
            <input
              class="submit"
              type="submit"
              @keydown.enter="submitHandler"
              :value="isLoading ? '' : 'Login'"
            />
          </div>

          <input
            class="cancel"
            type="button"
            value="Cancel"
            @click="cancelHandler"
          />
        </div>
      </form>

      <div v-if="isError" class="messageHandler" :class="{ failed: isError }">
        {{ isErrorText }}
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, inject, toRefs, reactive } from "vue";
import AccountPortalData from "@/data/AccountPortalData";
export default {
  setup() {
    const router = inject("$router");
    const formData = reactive({
      username: null,
      password: null,
    });
    const { authLoginHandler, isError, isErrorText, isLoading } =
      AccountPortalData();
    onMounted(() => {});

    const submitHandler = () => {
      isLoading.value = true;
      authLoginHandler(formData);
    };

    const cancelHandler = () => {
      router.push({ name: "index" });
    };

    return {
      isError,
      isErrorText,
      isLoading,
      ...toRefs(formData),
      submitHandler,
      authLoginHandler,
      cancelHandler,
    };
  },
};
</script>
<style scoped>
@media only screen and (max-width: 430px) {
  .wrapper {
    min-height: 100px;
    margin-top: 10px;
  }
  .logo {
    margin: 24px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo img {
    height: 35%;
    width: 35%;
  }
  .container {
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  .container h1 {
    color: var(--light-color);
    text-align: center;
  }
  .form-group {
    margin: 10px 0px;
  }
  .form-group input {
    min-width: 320px;
  }
  .action-button {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .action-button input {
    min-height: 50px;
    min-width: 320px;
  }
  .submit {
    background-color: var(--secondary-color);
  }
  .cancel {
    background-color: var(--secondary-color);
  }
  .footer {
    margin-top: 30px;
  }
  .messageHandler {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px 0px;
    border-radius: 10px;
    color: var(--light-color);
    font-weight: 500;
    min-width: 320px;
  }
  .failed {
    background-color: var(--error-color);
  }
}
</style>
