<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="27"
    viewBox="0 0 24 24"
    style="fill: rgba(255, 255, 255, 1); transform: ; msfilter: "
  >
    <path
      d="M13 5h9v2h-9zM2 7h7v2h2V3H9v2H2zm7 10h13v2H9zm10-6h3v2h-3zm-2 4V9.012h-2V11H2v2h13v2zM7 21v-6H5v2H2v2h3v2z"
    ></path>
  </svg>
</template>
