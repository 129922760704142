<template>
  <div class="setting-container">
    <div class="form-group">
      <div class="form-group-container">
        <label for="">Name</label>
        <input
          class="card-palette"
          type="text"
          :value="$store.state.AuthAgentProfile.agentname"
          disabled
        />
      </div>
    </div>
    <div class="form-group">
      <div class="form-group-container">
        <label for="">Email Address</label>
        <input
          class="card-palette"
          type="text"
          :value="$store.state.AuthAgentProfile.email"
          disabled
        />
      </div>
    </div>
    <div class="form-group">
      <div class="form-group-container">
        <label for="">Rank</label>
        <input
          class="card-palette"
          type="text"
          :value="$store.state.AuthAgentProfile.AgentRank"
          disabled
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  setup() {
    return {};
  },
};
</script>
<style scoped>
.form-group {
  margin-bottom: 5px;
}
.form-group-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.form-group-container label {
  color: var(--light-color);
  font-size: 14px;
  font-weight: 600;
}
.form-group-container input {
  margin: 3px 0px;
  min-width: 100%;
  color: var(--semi-light-color);
  font-weight: 700;
}
input[type="text"] {
  text-transform: none;
}
input::placeholder {
  color: var(--semi-light-color);
}
</style>
