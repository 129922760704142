import { reactive, toRefs, inject } from "vue";
import validationAlert from "@/utils/reusables/SwalAlert";
export default function applicationData() {
  const axios = inject("$axios");
  const store = inject("$store");
  const router = inject("$router");
  const Swal = inject("$swal");
  const state = reactive({
    agentInfo: [],
    planInfo: [],
    loading: false,
    countdown: 60,
    qrLoading: false,
  });
  const { errorHandler } = validationAlert();
  const fetchAgentItems = async (val) => {
    try {
      let agentcode = val;
      const { data } = await axios.get(`/meta/api/agents`, {
        params: {
          agentcode: agentcode,
        },
      });
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };
  const fetchPlanItems = async (val) => {
    try {
      let plancode = val;
      const { data } = await axios.get(`/meta/api/plans/`, {
        params: {
          plancode: plancode,
        },
      });
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };
  const createdClients = async (payment, amountInput) => {
    try {
      /*const timer = setInterval(() => {
        state.countdown--;
        if (state.countdown === 0) {
          clearInterval(timer);
          state.loading = false;
          state.countdown = 60; // Reset countdown
        }
      }, 1000); */

      let transactionID;
      store.state.formData.paymentmethod = payment;
      let formDetails = store.state.formData;

      if (!store.state.ifRetrievedData) {
        const formData = new FormData();
        formData.append(
          "file",
          store.state.formData.uploadFile,
          store.state.formData.ModFilename
        );
        let result = await axios.post(`/meta/api/local/files`, formData);
        if (result.status === 200) {
          const { data } = await axios.post("/meta/api/clients", {
            agentcode: formDetails.agentcode,
            plantypeid: formDetails.plantypeid,
            premium: formDetails.premium,
            payorname: formDetails.payorname,
            relationship: formDetails.relationship,
            membername: null,
            lastname: formDetails.lastname,
            firstname: formDetails.firstname,
            middlename: formDetails.middlename,
            suffix: formDetails.suffix,
            birthdate: formDetails.birthdate,
            civilstatus: formDetails.civilstatus,
            contactnumber: formDetails.contactnumber,
            email: formDetails.email,
            homeaddressOne: formDetails.homeaddressOne,
            homeaddressTwo: formDetails.homeaddressTwo,
            paymentmethod: formDetails.paymentmethod,
            status: "pending",
          });
          if (data.status) {
            state.loading = false;
            transactionID = data.data.id;
            Swal.fire({
              position: "bottom",
              icon: "success",
              background: "var(--primary-color)",
              color: "var(--light-color)",
              title:
                amountInput === 0
                  ? "Zero payment indicates that you did not pay any amount to your agent and your application is pending."
                  : "Your application is being processed.",
              showConfirmButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "var(--secondary-color)",
            }).then((result) => {
              if (result.isConfirmed) {
                if (amountInput) {
                  return router.push(
                    `/deped/alert/transaction/temp/meta/api/raw/${transactionID}`
                  );
                } else {
                  return router.push(
                    `/deped/alert/transaction/temp/meta/api/raw/hold/${data.data.referenceNumber}`
                  );
                }
              }
            });
          }
        }
      } else {
        Swal.fire({
          position: "bottom",
          icon: "success",
          background: "var(--primary-color)",
          color: "var(--light-color)",
          title:
            "We are processing your application, Once payment is processed, click OK.",
          showConfirmButton: true,
          allowOutsideClick: false,
          confirmButtonColor: "var(--secondary-color)",
        }).then((result) => {
          if (result.isConfirmed) {
            if (amountInput) {
              let transactionID = store.state.transactionId;
              return router.push(
                `/deped/alert/transaction/temp/meta/api/raw/${transactionID}`
              );
            }
          }
        });
      }
    } catch (error) {
      state.loading = false;
      errorHandler(error);
    }
  };
  const fetchAgentDataFile = async (filename) => {
    try {
      let name = filename;
      return await axios.get(`meta/api/local/files/agent/${name}`);
    } catch (error) {
      errorHandler(error);
    }
  };
  const fetchValueAddedBenefit = async (filename) => {
    try {
      let name = filename;
      return await axios.get(`meta/api/local/files/temp/${name}`);
    } catch (error) {
      errorHandler(error);
    }
  };

  return {
    ...toRefs(state),
    fetchAgentItems,
    fetchPlanItems,
    createdClients,
    fetchAgentDataFile,
    fetchValueAddedBenefit,
    errorHandler,
  };
}
