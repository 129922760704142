<template>
  <div class="wrapper">
    <div class="container">
      <div class="divider">
        <div class="header-icon">
          <greetingSVG id="svg" />
          <div class="header-message">
            <h2>You're almost done!</h2>
            <p style="text-indent: 8%">
              To complete your application,Input POS receipt or
              transaction/service invoice number corresponding to your receipt.
              Then click submitted, you will shortly receive an email
              notification.
            </p>
          </div>
          <form @submit.prevent="submitHandler()">
            <div class="input-confirmation-code">
              <input type="text" v-model="confirmationCode" required />
            </div>
            <div class="btn-confirmation-code loading-container">
              <app-loading v-if="isLoading" />
              <input
                type="submit"
                :value="isLoading ? '' : 'submit'"
                :disabled="isDisabled"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import adminData from "@/data/ProductionData";
import greetingSVG from "@/components/svgs/greetingSVG.vue";
import { useRoute } from "vue-router";
import { ref, inject } from "vue";
export default {
  components: {
    greetingSVG,
  },
  setup() {
    const { updateTransaction, isLoading } = adminData();
    const route = useRoute();
    const store = inject("$store");
    const transactionID = route.params.id;
    const confirmationCode = ref(null);
    const isDisabled = ref(false);
    const submitHandler = () => {
      isDisabled.value = true;
      isLoading.value = true;
      if (isDisabled.value) {
        updateTransaction(transactionID, confirmationCode.value);
        store.commit("resetState");
      } else {
        isDisabled.value = false;
      }
    };

    return {
      isDisabled,
      isLoading,
      updateTransaction,
      submitHandler,
      confirmationCode,
      transactionID,
    };
  },
};
</script>
<style scoped>
.divider {
  display: flex;
  flex-direction: column;
}
.header-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.header-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 20px;
}
.header-message h2 {
  color: var(--accent-color);
  padding: 10px 0px;
}
.header-message p {
  line-height: 24px;
  color: var(--semi-light-color);
  text-indent: 8%;
}
.header-message p b {
  color: var(--secondary-color);
}
#svg {
  margin: 30px 0px;
  height: 180px;
  width: 180px;
}
.input-confirmation-code {
  margin: 10px 0px;
}
.input-confirmation-code input {
  height: 60px;
  font-size: 27px;
  text-align: center;
}
.btn-confirmation-code input {
  height: 50px;
  width: 100%;
  font-size: 18px;
  text-align: center;
  background-color: var(--secondary-color);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--light-color);
}
</style>
