<template>
  <div class="section">
    <router-view />
  </div>
  <app-menu-bar />
</template>
<script>
export default {
  components: {},
  setup() {},
};
</script>
<style scoped>
.section {
  margin-bottom: 75px;
}
</style>
