<template>
  <div class="setting-container card-palette">
    <ul v-for="(item, index) in itemSetting" :key="index">
      <router-link :to="item.route" class="route">
        <li>
          <component class="icon" :is="item.icon"></component>
          {{ item.name }}
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
import ProfileComponent from "@/components/svgs/settingProfileSVG.vue";
import SecurityComponent from "@/components/svgs/settingPasswordSVG.vue";
import StatisticComponent from "@/components/svgs/settingStatSVG.vue";
import SystemInfoComponent from "@/components/svgs/settingInfoSVG.vue";
import SignOutComponent from "@/components/svgs/settingSignOutSVG.vue";
import { reactive } from "vue";
export default {
  components: {
    ProfileComponent,
    SecurityComponent,
    StatisticComponent,
    SystemInfoComponent,
    SignOutComponent,
  },
  setup() {
    const itemSetting = reactive([
      {
        name: "Personal Details",
        icon: "ProfileComponent",
        route: "/admin/auth/setting/profile",
      },
      {
        name: "Account Security",
        icon: "SecurityComponent",
        route: "/admin/auth/setting/changepassword",
      },
      {
        name: "My Statistic",
        icon: "StatisticComponent",
        route: "/admin/auth/setting/statistic",
      },
      {
        name: "System About",
        icon: "SystemInfoComponent",
        route: "/admin/auth/setting/about",
      },
      {
        name: "Log out",
        icon: "SignOutComponent",
        route: "/admin/auth/logout",
      },
    ]);
    return {
      itemSetting,
    };
  },
};
</script>
<style scoped>
.setting-container {
  border-radius: 10px;
  padding: 15px;
}
.setting-container ul .route > li {
  color: var(--semi-light-color);
  font-weight: 600;
  display: flex;
  align-items: center;
  min-height: 40px;
  gap: 8px;
}
.route {
  text-decoration: none;
}
.setting-container ul .route > li:hover {
  color: var(--light-color);
  border-radius: 5px;
}
.icon {
  height: 28px;
  width: 28px;
}
</style>
