import { inject, reactive, toRefs } from "vue";
import validationAlert from "@/utils/reusables/SwalAlert";
export default function adminHandler() {
  const axios = inject("$axios");
  const Swal = inject("$swal");
  const store = inject("$store");
  const router = inject("$router");
  const state = reactive({
    myTransactions: [],
    adminItemList: [],
    myClients: [],
    isError: false,
    isErrorMessage: null,
    isLoading: false,
  });
  const { errorHandler } = validationAlert();
  const updateClients = async (id, reference) => {
    try {
      await axios.put(`/meta/api/clients/${id}`, {
        status: "paid",
        reference: reference,
      });
      Swal.fire({
        title: "Payment Successful",
        text: "The application has been completed.",
        icon: "success",
        background: "var(--primary-color)",
        color: "var(--light-color)",
      });
      await axios.get(`/meta/api/auth/production?id=${id}`);
    } catch (error) {
      errorHandler(error);
    }
  };

  const updateTransaction = async (id, reference) => {
    try {
      let result = await axios.post(`/meta/api/auth/production?id=${id}`);
      if (result) {
        let res = await axios.put(`/meta/api/clients/${id}`, {
          status: "paid",
          reference: reference,
        });
        if (res) {
          state.isLoading = false;
          Swal.fire({
            title: "Payment Successful",
            text: "The application has been completed.",
            icon: "success",
            showConfirmButton: true,
            allowOutsideClick: false,
            background: "var(--primary-color)",
            color: "var(--light-color)",
            confirmButtonColor: "var(--secondary-color)",
          }).then((result) => {
            if (result.isConfirmed) {
              return router.push(`/`);
            }
          });
        }
      }
    } catch (error) {
      state.isLoading = false;
      errorHandler(error);
    }
  };

  const retrieveDataHandler = async (val) => {
    try {
      let reference = Number(val);
      const { data } = await axios.get(`/meta/api/clients/`, {
        params: {
          id: reference,
        },
      });
      if (data.status !== 400) {
        let agentCode = data[0].agentcode;
        router.push(`/qr-scanner/application-form/deped/${agentCode}`);
        let dataItem = data[0];
        store.commit("retrievedActivate", dataItem);
        state.isErrorMessage = null;
        state.isError = false;
      } else {
        state.isErrorMessage = data.message;
        state.isError = true;
      }
    } catch (error) {
      errorHandler(error);
    }
  };
  return {
    ...toRefs(state),
    updateClients,
    updateTransaction,
    retrieveDataHandler,
    errorHandler,
  };
}
