import { toRefs, reactive, inject } from "vue";
export default function qrGeneratorHandler() {
  const store = inject("$store");
  const state = reactive({
    QRCODE: null,
    isRender: false,
  });
  const qrGenerator = async () => {
    let agentcode = store.state.AuthAgentProfile.Agentcode;
    let patternCode = "IMS";
    let MAX_NUMBER = 10000000000;
    let patternNumber = Math.floor(Math.random() * MAX_NUMBER);
    state.isRender = true;
    state.QRCODE = `${agentcode}?${patternCode}${patternNumber}_INVALID_QR`;
  };
  return {
    qrGenerator,
    ...toRefs(state),
  };
}
