<template>
  <div class="wrapper">
    <div class="menu-action">
      <span class="menu-back card-palette" v-if="isHide">
        <SettingBack class="icon" @click="routeHandler" v-if="isHide" />
      </span>

      <h2>Settings</h2>
    </div>
    <div class="profile-container card-palette">
      <img :src="$store.state.AuthAgentProfileImage" alt="Agent Profile" />
      <div class="user-info">
        <h4>{{ $store.state.AuthAgentProfile.agentname }}</h4>
        <h6>{{ $store.state.AuthAgentProfile.email }}</h6>
      </div>
    </div>
    <div class="subtitle">
      <h3>{{ $route.meta.title }}</h3>
    </div>
  </div>
</template>

<script>
import SettingBack from "@/components/svgs/settingBackSVG.vue";
import { computed, inject } from "vue";
export default {
  components: {
    SettingBack,
  },
  setup() {
    const router = inject("$router");
    const settingLayout = inject("$layout");
    const routeHandler = () => {
      router.push({ name: "authAgentSetting" });
    };
    const isHide = computed(() => {
      return settingLayout.value !== "authAgentSetting" ? true : false;
    });

    return {
      routeHandler,
      settingLayout,
      isHide,
    };
  },
};
</script>
<style scoped>
.menu-action {
  display: flex;
  align-items: center;
  gap: 16px;
}
.menu-action h2 {
  color: var(--semi-light-color);
}
.profile-container {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 90px;
  padding: 0px 20px;
  border-radius: 12px;
  color: var(--light-color);
}
.profile-container .user-info h4 {
  color: var(--accent-color);
}
.profile-container img {
  height: 50px;
  width: 50px;
  clip-path: circle();
}
.subtitle {
  margin: 10px 0px;
  color: var(--semi-light-color);
}
.icon {
  height: 28px;
  width: 28px;
}
</style>
