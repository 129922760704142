<template>
  <AgentPortfolio />
</template>
<script>
import AgentPortfolio from "@/components/partials/portal/AgentPortfolio.vue";
export default {
  components: {
    AgentPortfolio,
  },
  setup() {},
};
</script>
