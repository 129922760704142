<template>
  <nav>
    <div class="content">
      <div class="col-one">
        <img :src="require('@/assets/anec-logo-header.jpg')" alt="header" />
      </div>
    </div>
  </nav>
</template>
<style scoped>
nav {
  background-color: var(--primary-color);
  box-shadow: rgba(99, 100, 100, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
nav .content {
  max-width: 1320px;
  margin: auto;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav .content .col-one img {
  height: 140px;
  width: auto;
}
@media only screen and (max-width: 430px) and (min-width: 320px) {
  nav .content {
    min-height: 100px;
  }
  nav .content .col-one img {
    height: 80px;
  }
}
</style>
