<template>
  <AgentSettingIndex />
</template>
<script>
import AgentSettingIndex from "@/components/partials/portal/AgentSetting.vue";
export default {
  components: {
    AgentSettingIndex,
  },
  setup() {},
};
</script>
