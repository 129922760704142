import { reactive, toRefs, inject } from "vue";
import validationAlert from "@/utils/reusables/SwalAlert";
export default function accountPortal() {
  const axios = inject("$axios");
  const router = inject("$router");
  const store = inject("$store");
  const state = reactive({
    isMessage: false,
    isMessageText: null,
    isSuccess: false,
    isErrorText: null,
    isError: false,
    countTotal: 0,
    countPaid: 0,
    countUnpaid: 0,
    itemClients: [],
    isLoading: false,
    clientDetails: [],
    isRender: false,
  });
  const { errorHandler } = validationAlert();
  const authLoginHandler = async (formData) => {
    try {
      let { data } = await axios.post("admin/portal/auth", formData);
      state.isLoading = false;
      if (data.status === 201) {
        localStorage.setItem(
          `${process.env.VUE_APP_AUTH_TOKEN}_XXX`,
          data.data
        );
        authProfileHandler();
      } else {
        state.isErrorText = data.message;
        state.isError = true;
      }
      setTimeout(() => {
        state.isError = false;
      }, 3000);
    } catch (error) {
      state.isLoading = false;
      errorHandler(error);
    }
  };
  const authProfileHandler = async () => {
    try {
      let { data } = await axios.get("/admin/portal/auth/profile");

      if (data) {
        let profile = await axios.get(
          `meta/api/local/files/agent/${data.profile}`
        );
        if (profile) {
          let urlProfile = profile.request.responseURL;
          store.commit("RetrievedAgentProfile", {
            data: data,
            profile: urlProfile,
          });
          return router.push("/admin/auth/profile");
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };
  const authChangePasswordHandler = async (formData) => {
    try {
      let { data } = await axios.post(
        "/admin/portal/auth/changepassword",
        formData
      );
      if (data.code === "SC") {
        let { data } = await axios.get("/admin/portal/auth/profile");
        store.commit("RetrievedAgentProfile", {
          data: data,
          profile: store.state.AuthAgentProfileImage,
        });
        state.isSuccess = true;
      } else {
        state.isSuccess = false;
      }
      state.isLoading = false;
      state.isMessageText = data.message;
      state.isMessage = true;
      setTimeout(() => {
        state.isMessage = false;
      }, 3000);
    } catch (error) {
      errorHandler(error);
    }
  };
  const authPortfolioCount = async () => {
    try {
      let { data } = await axios.get("/admin/portal/auth/portfolio/count");
      state.countTotal = data.totalPortfolio;
      state.countPaid = data.totalPaid;
      state.countUnpaid = data.totalUnpaid;
    } catch (error) {
      errorHandler(error);
    }
  };
  const authPortfolioClients = async (val) => {
    try {
      let searchEngine = !val ? "" : val;
      let { data } = await axios.get(`/admin/portal/auth/portfolio/clients`, {
        params: {
          search: searchEngine,
        },
      });
      state.itemClients = data.sort((a, b) => b.id - a.id);
      state.isRender = true;
      authPortfolioCount();
    } catch (error) {
      errorHandler(error);
    }
  };
  const authPortfolioClientsById = async (val) => {
    try {
      let id = val;
      let { data } = await axios.get(
        `/admin/portal/auth/portfolio/clients/${id}`
      );
      state.isRender = true;
      state.clientDetails = data[0];
    } catch (error) {
      errorHandler(error);
    }
  };
  return {
    ...toRefs(state),
    authLoginHandler,
    authProfileHandler,
    authChangePasswordHandler,
    authPortfolioCount,
    authPortfolioClients,
    authPortfolioClientsById,
    errorHandler,
  };
}
