<template>
  <div class="wrapper">
    <div class="container">
      <div class="divider">
        <div class="header-title">
          <h2>Client Information</h2>
        </div>
        <div class="content-form">
          <form>
            <div class="form-group" v-if="isDependent">
              <label> Payor Name: <span class="required">*</span></label>
              <div class="input">
                <input
                  type="text"
                  v-model="payorname"
                  placeholder="Fullname"
                  :class="{
                    error: errorField.includes('payorname') && !payorname,
                  }"
                />
              </div>
            </div>
            <div class="form-group" v-if="isDependent">
              <label>
                Relation to Insured: <span class="required">*</span></label
              >
              <div class="input">
                <select
                  v-model="selectedRelation"
                  :class="{
                    error:
                      errorField.includes('relationship') && !selectedRelation,
                  }"
                >
                  <option disabled value="">--Please select--</option>
                  <option v-for="(item, index) in relationItems" :key="index">
                    {{ item.relation }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label>
                <span v-if="isDependent">Insured</span> Lastname:
                <span class="required">*</span></label
              >
              <div class="input">
                <input
                  type="text"
                  v-model="lastname"
                  placeholder="Dela cruz"
                  :class="{
                    error: errorField.includes('lastname') && !lastname,
                  }"
                />
              </div>
            </div>
            <div class="form-group">
              <label>
                <span v-if="isDependent">Insured</span> Firstname:
                <span class="required">*</span></label
              >
              <div class="input">
                <input
                  type="text"
                  v-model="firstname"
                  placeholder="Juan"
                  :class="{
                    error: errorField.includes('firstname') && !firstname,
                  }"
                />
              </div>
            </div>
            <div class="form-group">
              <label>
                <span v-if="isDependent">Insured</span> Middlename:
              </label>
              <div class="input">
                <input
                  type="text"
                  v-model="middlename"
                  placeholder="Middlename"
                />
              </div>
            </div>
            <div class="form-group">
              <label> <span v-if="isDependent">Insured</span> Suffix: </label>
              <div class="input">
                <input
                  type="text"
                  v-model="suffix"
                  placeholder="if any (e.g. Jr, Sr)"
                />
              </div>
            </div>
            <div class="form-group">
              <label> Birth Date: <span class="required">*</span></label>
              <div class="input">
                <input
                  @blur="birthdateValidation()"
                  type="date"
                  v-model="birthdate"
                  :class="{
                    error: errorField.includes('birthdate') && !birthdate,
                  }"
                />
              </div>
            </div>
            <div class="form-group">
              <label> Civil Status: <span class="required">*</span></label>
              <div class="input">
                <select
                  v-model="selectedCivilStatus"
                  :class="{
                    error:
                      errorField.includes('civilstatus') &&
                      !selectedCivilStatus,
                  }"
                >
                  <option disabled value="">--Please select--</option>
                  <option
                    v-for="(item, index) in civilstatusItems"
                    :key="index"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label> Contact Number: <span class="required">*</span></label>
              <div class="input">
                <input
                  :class="{
                    error:
                      errorField.includes('contactnumber') && !contactnumber,
                  }"
                  type="text"
                  v-model="contactnumber"
                  placeholder="09XX XXX XXXX"
                />
              </div>
            </div>
            <div class="form-group">
              <label> Email Address: <span class="required">*</span></label>
              <div class="input">
                <input
                  type="email"
                  v-model="email"
                  placeholder="juan.delacruz@mail.com"
                  :class="{
                    error: errorField.includes('email') && !email,
                  }"
                />
              </div>
            </div>
            <div class="form-group">
              <label> School Name: <span class="required">*</span></label>
              <div class="input">
                <input
                  type="text"
                  v-model="homeaddressOne"
                  :class="{
                    error:
                      errorField.includes('homeaddressOne') && !homeaddressOne,
                  }"
                />
              </div>
            </div>
            <div class="form-group">
              <label> School Address: <span class="required">*</span></label>
              <div class="input">
                <input
                  type="text"
                  v-model="homeaddressTwo"
                  :class="{
                    error:
                      errorField.includes('homeaddressTwo') && !homeaddressTwo,
                  }"
                />
              </div>
            </div>
            <div class="form-group" v-if="!$store.state.ifRetrievedData">
              <label>
                Upload Valid Government ID:
                <span class="required">*</span></label
              >
              <div class="input-file">
                <input
                  id="idCard"
                  ref="fileData"
                  type="file"
                  @change="selectFile"
                />
              </div>
              <div v-if="!fileData"></div>
              <div id="preview">
                <img v-if="item.imageUrl" :src="item.imageUrl" />
              </div>
            </div>
            <div class="form-group" v-if="false">
              <label> Beneficiary Name </label>
              <div class="input">
                <input type="text" />
              </div>
            </div>
            <div class="form-group" v-if="false">
              <label> Relationship: </label>
              <div class="input">
                <select>
                  <option>Mother</option>
                  <option>Father</option>
                </select>
              </div>
            </div>
            <div class="form-group" v-if="false">
              <label> Birth Date: </label>
              <div class="input">
                <input type="date" />
              </div>
            </div>
            <div v-if="!fileData"></div>
          </form>
        </div>
        <div class="pagination">
          <div class="btn-action">
            <input
              type="button"
              value="Back"
              @click="$emit('actionPage', 'PlanDetails')"
            />
            <input type="button" value="Next" @click="nextHandler()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import validationAlert from "@/utils/reusables/SwalAlert";
import { reactive, toRefs, ref, inject, onActivated } from "vue";
export default {
  components: {},
  setup(props, { emit }) {
    const store = inject("$store");
    const moment = inject("$moment");
    const formData = reactive({
      payorname: null,
      relationship: null,
      lastname: null,
      firstname: null,
      middlename: null,
      suffix: null,
      birthdate: null,
      civilstatus: null,
      contactnumber: null,
      email: null,
      homeaddressOne: null,
      homeaddressTwo: null,
      uploadFile: null,
      ModFilename: null,
    });
    const item = reactive({
      image: null,
      imageUrl: null,
    });
    const itemSelfie = reactive({
      image: null,
      imageUrl: null,
    });
    const { clientPortal } = validationAlert();

    const errorField = ref([]);
    const fileData = ref(null);
    const fileDataSelfie = ref(null);
    const isDependent = ref(false);
    const selectedRelation = ref("");
    const selectedCivilStatus = ref("");
    const isAgeLimit = ref(false);
    const civilstatusItems = reactive([
      "Single",
      "Married",
      "Separated",
      "Divorced",
    ]);
    const relationItems = reactive([
      {
        relation: "Father",
        level: "secondary",
      },
      {
        relation: "Mother",
        level: "secondary",
      },
      {
        relation: "Husband",
        level: "secondary",
      },
      {
        relation: "Wife",
        level: "secondary",
      },
      {
        relation: "Daughter",
        level: "primary",
      },
      {
        relation: "Son",
        level: "primary",
      },
    ]);

    onActivated(() => {
      if (store.state.ifRetrievedData) {
        isDependent.value = store.state.formData.ifDependent;
        selectedCivilStatus.value = store.state.formData.civilstatus;
        selectedRelation.value = store.state.formData.relationship;
        formData.relationship = store.state.formData.relationship;

        formData.payorname = store.state.formData.payorname;
        formData.lastname = store.state.formData.lastname;
        formData.firstname = store.state.formData.firstname;
        formData.middlename = store.state.formData.middlename;
        formData.suffix = store.state.formData.suffix;
        formData.birthdate = store.state.formData.birthdate;
        formData.civilstatus = store.state.formData.civilstatus;
        formData.contactnumber = store.state.formData.contactnumber;
        formData.email = store.state.formData.email;
        formData.homeaddressOne = store.state.formData.homeaddressOne;
        formData.homeaddressTwo = store.state.formData.homeaddressTwo;
        formData.uploadFile = "DEPED";
        formData.ModFilename = "DEPED";
      } else {
        let ifDependent = store.state.formData.ifDependent;
        isDependent.value = ifDependent ? true : false;
      }
    });

    const selectFile = (e) => {
      const file = e.target.files[0];
      item.image = file;
      item.imageUrl = URL.createObjectURL(file);
      if (fileData.value.files.length > 0) {
        fileData.value = fileData.value.files[0];

        formData.uploadFile = fileData.value;
        formData.ModFilename = `${
          fileData.value.lastModified
        }.${fileData.value.name.split(".").pop()}`;
      } else {
        formData.ModFilename = null;
      }
    };
    const selectDisplay = (e) => {
      const file = e.target.files[0];
      itemSelfie.image = file;
      itemSelfie.imageUrl = URL.createObjectURL(file);
      if (fileDataSelfie.value.files.length > 0) {
        fileDataSelfie.value = fileDataSelfie.value.files[0];

        formData.uploadFileSelfie = fileDataSelfie.value;
        formData.ModFilenameSelfie = `${
          fileDataSelfie.value.lastModified
        }.${fileDataSelfie.value.name.split(".").pop()}`;
      } else {
        formData.ModFilenameSelfie = null;
      }
    };
    const nextHandler = () => {
      formData.civilstatus = selectedCivilStatus.value;
      const validate = [];
      Object.entries(formData).forEach(([key, value]) => {
        if (isDependent.value) {
          if (key !== "middlename" && key !== "suffix") {
            if (!value) {
              validate.push(key);
            }
          }
        } else {
          if (
            key !== "middlename" &&
            key !== "suffix" &&
            key !== "payorname" &&
            key !== "relationship"
          ) {
            if (!value) {
              validate.push(key);
            }
          }
        }
      });
      errorField.value = validate;

      if (validate.length) {
        clientPortal("fillout");
      } else if (isAgeLimit.value) {
        clientPortal("notQualified");
      } else if (!/^[^@]+@\w+(\.\w+)+\w$/.test(formData.email)) {
        clientPortal("invalidemail");
      } else {
        store.state.clientDetails = formData;
        store.commit("formDataHandler");
        emit("actionPage", "TermCondition");
      }
    };
    const birthdateValidation = () => {
      let enrollAge = moment().diff(
        moment(formData.birthdate, "YYYY-MM-DD"),
        "years"
      );

      if (!isDependent.value) {
        let UNDER_AGE_LIMIT = 18;
        let OVER_AGE_LIMIT = 65;

        if (enrollAge > OVER_AGE_LIMIT || enrollAge < UNDER_AGE_LIMIT) {
          clientPortal("notQualified");
          isAgeLimit.value = true;
        } else {
          isAgeLimit.value = false;
        }
        formData.relationship = null;
      } else {
        formData.relationship = selectedRelation.value;
        let PRIMARY_UNDER_AGE_LIMIT = 4;
        let PRIMARY_OVER_AGE_LIMIT = 24;

        let SECONDARY_UNDER_AGE_LIMIT = 18;
        let SECONDARY_OVER_AGE_LIMIT = 63;

        let result = relationItems.find((val) => {
          return val.relation === formData.relationship;
        });
        //Primary
        if (result.level === "primary") {
          if (
            enrollAge > PRIMARY_OVER_AGE_LIMIT ||
            enrollAge <= PRIMARY_UNDER_AGE_LIMIT
          ) {
            clientPortal("notQualified");
            isAgeLimit.value = true;
          } else {
            isAgeLimit.value = false;
          }
        } else {
          if (
            enrollAge > SECONDARY_OVER_AGE_LIMIT ||
            enrollAge <= SECONDARY_UNDER_AGE_LIMIT
          ) {
            clientPortal("notQualified");
            isAgeLimit.value = true;
          } else {
            isAgeLimit.value = false;
          }
        }
      }
    };
    return {
      ...toRefs(formData),
      errorField,
      nextHandler,
      clientPortal,
      fileData,
      selectFile,
      birthdateValidation,
      item,
      itemSelfie,
      selectDisplay,
      fileDataSelfie,
      isDependent,
      relationItems,
      civilstatusItems,
      selectedRelation,
      selectedCivilStatus,
      isAgeLimit,
    };
  },
};
</script>
<style scoped>
.divider {
  display: flex;
  flex-direction: column;
}
.content-form {
  margin: 10px 18px;
}
.content-form form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.content-form form .form-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}
.content-form form .form-group label {
  color: var(--light-color);
  font-weight: 600;
}
.required {
  color: var(--secondary-color);
  font-weight: 600;
}
.content-form form .form-group .input input,
.content-form form .form-group .input-file input,
.content-form form .form-group .input select {
  min-width: 100%;
}
.pagination {
  margin: 20px 30px;
}
.pagination .btn-action {
  display: flex;
  justify-content: space-between;
}
input#idCard {
  display: inline-block;
  width: 100%;
  padding: 120px 0 0 0;
  height: 60px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url("https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-cloud-upload-outline-512.png")
    center center no-repeat;
  border-radius: 20px;
  background-size: 60px 60px;
}
#preview img {
  height: 100%;
  width: 100%;
}
</style>
