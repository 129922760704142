import { createStore } from "vuex";
import persistedStateVuex from "persisted-state-vuex";

const getDefaultState = () => {
  return {
    planDetails: [],
    agentDetails: [],
    clientDetails: [],
    formData: {
      initialcode: null,
      agentcode: null,
      plantypeid: null,
      premium: null,
      lastname: null,
      firstname: null,
      middlename: null,
      suffix: null,
      birthdate: null,
      civilstatus: null,
      contactnumber: null,
      email: null,
      homeaddressOne: null,
      homeaddressTwo: null,
      paymentmethod: null,
      transactionType: null,
      amountPaidOne: null,
      amountPaidTwo: null,
      upload_id: null,
      uploadFile: null,
      ModFilename: null,
      upload_selfie: null,
      uploadFileSelfie: null,
      ModFilenameSelfie: null,
    },
    agentProfile: {
      agentcode: null,
      name: null,
    },
    clientProfile: [],
    AuthAgentProfile: null,
    AuthAgentIsForcedPassword: 0,
    AuthAgentProfileImage: null,
    ifRetrievedData: false,
    transactionId: null,
  };
};

export default createStore({
  state: getDefaultState(),
  getters: {},
  mutations: {
    formDataHandler(state) {
      state.formData.agentcode = state.agentDetails.Agentcode;
      state.formData.initialcode = state.agentDetails.initialCode;
      state.formData.plantypeid = state.planDetails.PlantypeID;
      state.formData.premium = state.planDetails.Premium;
      state.formData.plancode = state.planDetails.PlanCode;
      state.formData.ifDependent = state.planDetails.ifDependent;

      //Client Information
      if (!state.ifRetrievedData) {
        state.formData.payorname = state.clientDetails.payorname;
        state.formData.relationship = state.clientDetails.relationship;
        state.formData.lastname = state.clientDetails.lastname;
        state.formData.firstname = state.clientDetails.firstname;
        state.formData.middlename = state.clientDetails.middlename;
        state.formData.suffix = state.clientDetails.suffix;
        state.formData.birthdate = state.clientDetails.birthdate;
        state.formData.civilstatus = state.clientDetails.civilstatus;
        state.formData.contactnumber = state.clientDetails.contactnumber;
        state.formData.email = state.clientDetails.email;
        state.formData.homeaddressOne = state.clientDetails.homeaddressOne;
        state.formData.homeaddressTwo = state.clientDetails.homeaddressTwo;
        state.formData.uploadFile = state.clientDetails.uploadFile;
        state.formData.ModFilename = state.clientDetails.ModFilename;
        state.formData.uploadFileSelfie = state.clientDetails.uploadFileSelfie;
        state.formData.ModFilenameSelfie =
          state.clientDetails.ModFilenameSelfie;
      }
    },
    fetchAgentProfile(state, payload) {
      let item = payload.data;
      state.agentProfile.agentcode = item.Agentcode;
      state.agentProfile.name = item.agentname || item.name;
    },
    fetchClientProfile(state, payload) {
      let item = payload.data;
      state.clientProfile = item;
    },
    retrievedActivate(state, data) {
      state.ifRetrievedData = true;
      state.formData.plancode = data.plan_type.PlanCode;
      state.planDetails = data.plan_type;
      state.transactionId = data.id;
      //
      state.formData.payorname = data.payorname;
      state.formData.relationship = data.relationship;
      state.formData.lastname = data.lastname;
      state.formData.firstname = data.firstname;
      state.formData.middlename = data.middlename;
      state.formData.suffix = data.suffix;
      state.formData.birthdate = data.birthdate;
      state.formData.civilstatus = data.civilstatus;
      state.formData.contactnumber = data.contactnumber;
      state.formData.email = data.email;
      state.formData.homeaddressOne = data.homeaddressOne;
      state.formData.homeaddressTwo = data.homeaddressTwo;
    },
    retrievedDeactivate(state) {
      state.ifRetrievedData = false;
    },
    RetrievedAgentProfile(state, payLoad) {
      state.AuthAgentProfile = payLoad.data;
      state.AuthAgentIsForcedPassword = payLoad.data.isForcedPassword;
      state.AuthAgentProfileImage = payLoad.profile;
    },
    resetState(state) {
      //Object.assign(state, getDefaultState());
      const authAgentProfile = state.AuthAgentProfile;
      const AuthAgentIsForcedPassword = state.AuthAgentIsForcedPassword;
      const AuthAgentProfileImage = state.AuthAgentProfileImage;
      Object.assign(state, getDefaultState());
      state.AuthAgentProfile = authAgentProfile;
      state.AuthAgentIsForcedPassword = AuthAgentIsForcedPassword;
      state.AuthAgentProfileImage = AuthAgentProfileImage;
    },
  },
  actions: {},
  modules: {},
  plugins: [persistedStateVuex.init],
});
