<template>
  <AgentSettingStatistic />
</template>
<script>
import AgentSettingStatistic from "@/components/partials/portal/AgentSettingStatistic.vue";
export default {
  components: {
    AgentSettingStatistic,
  },
  setup() {},
};
</script>
