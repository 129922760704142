<template>
  <div class="wrapper">
    <div class="logo">
      <img :src="require('@/assets/brand-logo.png')" alt="logo" />
    </div>
    <div class="container">
      <h4 class="label-note">
        Scan the QR code provided by marketing executive.
      </h4>
      <div id="reader"></div>
      <br />
      <div v-if="isLoading" class="container-loading">
        <img
          class="qr-scan"
          :src="require('@/assets/qr-scanning.gif')"
          alt="scanner"
        />
      </div>
      <div v-else class="container-result" v-show="scannedQrcodes">
        <div>
          <span
            v-show="scannedQrcodes"
            class="alert"
            :class="isValid ? 'valid' : 'invalid'"
          >
            {{ scannedQrcodes }}
          </span>
        </div>
        <div class="action">
          <input
            class="back"
            v-show="scannedQrcodes"
            type="button"
            :value="isValid ? 'Back' : 'Try Again'"
            @click="createScanQrCodes(), (scannedQrcodes = null)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, inject } from "vue";
import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode";
//import Swal from "sweetalert2";
//import router from "@/router";
export default {
  setup() {
    const router = inject("$router");
    const store = inject("$store");
    const scannedQrcodes = ref(null);
    const isValid = ref(false);
    const isLoading = ref(false);
    const html5QrcodeScanner = ref();
    const isVisible = ref(true);

    onMounted(() => {
      store.commit("retrievedDeactivate");
      createScanQrCodes();
    });
    const createScanQrCodes = () => {
      html5QrcodeScanner.value = new Html5QrcodeScanner(
        "reader",
        {
          fps: 10,
          qrbox: 250,
          aspectRatio: 1.7777778,
          videoConstraints: {
            facingMode: { exact: "environment" },
          },
          supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
        },
        false
      );
      html5QrcodeScanner.value.render(onScanSuccess);
    };

    const onScanSuccess = (decodeResult) => {
      isLoading.value = true;
      isVisible.value = false;
      let ifValidQRCode = decodeResult.includes("IMS");
      let getAgentCode = decodeResult.substring(
        0,
        decodeResult.indexOf("?IMS")
      );

      if (ifValidQRCode) {
        ///scannedQrcodes.value = "The QR code has been validated successfully.";
        router.push(`/qr-scanner/application-form/deped/${getAgentCode}`);
      } else {
        isValid.value = false;
        scannedQrcodes.value = "Invalid QR Code, Please scan valid QR code.";
        setTimeout(() => {
          isLoading.value = false;
        }, 5000);
      }
      html5QrcodeScanner.value.clear();

      //window.open(scannedQrcodes.value, "_blank");
    };

    return {
      createScanQrCodes,
      scannedQrcodes,
      onScanSuccess,
      isValid,
      html5QrcodeScanner,
      isVisible,
      isLoading,
    };
  },
};
</script>
<style scoped>
@media only screen and (max-width: 430px) and (min-width: 320px) {
  .logo {
    margin: 35px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo img {
    height: 40%;
    width: 40%;
  }
  .container {
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: 5% 5% 0% 5%;
  }
}
.alert {
  height: 20px;
  padding: 12px;
  border-radius: 5px;
  font-size: 14px;
}
.invalid {
  background-color: var(--error-color);
  color: var(--light-color);
}
.valid {
  background-color: var(--valid-color);
  color: var(--light-color);
}
.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-loading img {
  height: 140px;
  width: 140px;
}
.container-result {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}
.action {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.back {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-decoration: none;
  color: var(--light-color);
  font-size: 18px;
  border-radius: 10px;
  width: 200px;
  height: 40px;
  font-weight: bold;
  transition: 0.3s;
  background-color: var(--secondary-color);
}
</style>
