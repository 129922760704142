<template>
  <AuthSettingAbout />
</template>
<script>
import AuthSettingAbout from "@/components/partials/portal/AgentSettingAbout.vue";
export default {
  components: {
    AuthSettingAbout,
  },
  setup() {},
};
</script>
